<template>
<div>

  <div class="zex-logo">
    <img src="./assets/img/ZEXnew.png" style="align-self: center; margin-left: 12vw; width: 180px;">
  </div>

  <div class="zex-logo-mob">
    <img src="./assets/img/ZEXnewmob.png" style="align-self: center; width: 100%;">
  </div>

  <div class="dada">
    <router-view/>
  </div>

  <div class="sasa">
    <span>Zé Express © Todos os direitos reservados.</span>
    <div style="display: flex; align-items: center; justify-content: center;">
      <span>SIGA A GENTE!</span>
      <img  src="./assets/img/Grupo 4.png" @click="sendto('https://www.instagram.com/zeexpressbr/')" style="margin: 0px 5px; cursor: pointer;">
      <img style="cursor: pointer;" src="./assets/img/Grupo 5.png" @click="sendto('https://br.linkedin.com/company/zedelivery')">
    </div>
  </div>


</div>
</template>

<script>
  export default{
    methods:{
      sendto(link){
        window.open(link, '_blank');
      }
    }

  }


</script>



<style scoped>

@font-face {
    font-family: 'roboto';
    src: url('./assets/font/Roboto-Regular.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'bold';
    src: url('./assets/font/Roboto-Bold.ttf');
    font-weight: bold;
}

button {
  all: unset; /* Resets all inherited styles */
  cursor: pointer; /* Ensures pointer cursor */
  display: inline-block; /* Ensures proper box model */
}

span {
    font-family: 'roboto';
}

@media(min-width: 851px){
  .sasa {
  height: 10vh;
  width: 100%;
  z-index:9998; 
  display: flex; 
  position:absolute; 
  bottom: 0;
  align-items: center; 
  gap: 20px; 
  justify-content: center;
}

.dada{
  background-image: url('./assets/img/bg.png');
    background-size: cover; /* Faz com que a imagem cubra toda a área */
    background-position: bottom; /* Centraliza a imagem no fundo */
    background-repeat: no-repeat; /* Evita que a imagem se repita */
    background-color:  #fff200ab ;
     height: 82vh;
     margin-top: 7.5vh;
     background-attachment: fixed; 

}

.zex-logo-mob {
  display: none;
}
}

@media(max-width: 851px){
  .sasa {
  height: 10vh;
  width: 100%;
  z-index:9998; 
  display: flex; 
  bottom: 0;
  align-items: center; 
  gap: 20px; 
  justify-content: center;
  font-size: 2vh;
}
}

@media(min-width: 850px){
  .zex-logo {
    z-index: 9999;
    display: flex;
    align-items: start;
    justify-content: start;
    height: 7.5vh;
    position: fixed;
    top: 0; width: 100%;
    background-color: white;
  }
}

@media(max-width: 850px){

  .zex-logo {
    display: none;
  }

  .zex-logo-mob {
    width:100%;
  }

    .dada{
      background-image: url('./assets/img/bg.png');
      background-size: cover; /* Faz com que a imagem cubra toda a área */
    background-position: center; /* Centraliza a imagem no fundo */
    background-repeat: no-repeat; /* Evita que a imagem se repita */
      min-height: 100vh;
      height: auto !important
    }

    .sasa{
      flex-direction: column-reverse;
      height: 13vh !important;
      justify-content: center !important;
      padding: 0 !important;
    }

    p{
      margin: 0;
    }

}


</style>
